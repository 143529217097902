import React from 'react'
// import Spline from '..components/spline/Spline'
import SplineComp from '../components/spline/Spline.jsx';

const Styles = {
    marginBottom:5+"rem",
    display:"flex",
    justifyContent:"end",
    textAlign: "center"
}


function LandingPage() {
  return (
    <>
        <SplineComp />
      <div className="container" style={Styles}>
        {/* <a className='btn btn-primary' href="/top" >Дэлгэрэнгүй...</a> */}
      </div>
    </>
  )
}

export default LandingPage