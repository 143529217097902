import React from 'react'
import './blog_component.css'
import eimage from '../../assets/eimage007.png'

function BlogHeader(props) {
  return (
    <div className='blg__Header'>
        <img src={eimage} alt="engineer_image" />
        <div className="title__wrap">
          <h1>{props.text.title}</h1>
          <h2>{props.text.subtitle}</h2>
        </div>
    </div>
  )
}

export default BlogHeader
