
import React, { useState } from 'react'
import './nav.css';
import { IoHomeOutline } from "react-icons/io5";
import { FaChild } from "react-icons/fa";
import { GrUserWorker } from "react-icons/gr";
// import { FaAward } from "react-icons/fa";
import { CiMountain1 } from "react-icons/ci";
import { LiaGratipay } from "react-icons/lia";
import { RiMemoriesLine } from "react-icons/ri";


const Nav = () => {
  const[activeNav,setActiveNav] = useState('');
  return (
    <nav>
      <a href="#top" onClick={()=>setActiveNav('#top')} className={activeNav === '#top' ? 'active':''}><IoHomeOutline /></a>
      <a href="#child" onClick={()=>setActiveNav('#child')} className={activeNav === '#child' ? 'active': ''}><FaChild /></a>
      <a href="#career" onClick={()=>setActiveNav('#career')} className={activeNav === '#career' ? 'active':''}><GrUserWorker /></a>
      {/* <a href="#achievements" onClick={()=>setActiveNav('#achievements')} className={activeNav === '#achievements' ? 'active':''}><FaAward /></a> */}
      <a href="#challenge" onClick={()=>setActiveNav('#challenge')} className={activeNav === '#challenge' ? 'active':''}><CiMountain1 /></a>
      <a href="#gratitude" onClick={()=>setActiveNav('#gratitude')} className={activeNav === '#gratitude' ? 'active':''}><LiaGratipay /></a>
      <a href="#testimonials" onClick={()=>setActiveNav('#testimonials')} className={activeNav === '#testimonials' ? 'active':''}><RiMemoriesLine /></a>
    </nav>
  )
}

export default Nav