import React from 'react'
import './testimonials.css'
import { Link } from 'react-router-dom';


import AVTR1 from '../../assets/bi-removebg-preview.png'
// import AVTR2 from '../../assets/avatar2.jpg'
// import AVTR3 from '../../assets/avatar3.jpg'
// import AVTR4 from '../../assets/avatar3.jpg'
// import Swiper core and required modules
import { Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



const data = [
  {
    avatar:AVTR1,
    name:"П.Цолмон",
    review:'  Унтаж байгаад нэг сэрсэн удаан хугацаанд гэртээ байхгүй байсан аав маань ирчихэж , зөндөө гоё бэлэгнүүд бид бүгдэд авчиржээ хувцас хунар '
  },
  // {
  //   avatar:AVTR2,
  //   name:"lesborn",
  //   review:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab asperiores veritatis fuga reiciendis ad adipisci! Rem vel eligendi dolor perspiciatis consequatur eveniet ratione exercitationem alias quis nihil ab, neque possimus!'
  // },
  // {
  //   avatar:AVTR3,
  //   name:"Tokyo",
  //   review:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab asperiores veritatis fuga reiciendis ad adipisci! Rem vel eligendi dolor perspiciatis consequatur eveniet ratione exercitationem alias quis nihil ab, neque possimus!'
  // },
  // {
  //   avatar:AVTR4,
  //   name:"Karna",
  //   review:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab asperiores veritatis fuga reiciendis ad adipisci! Rem vel eligendi dolor perspiciatis consequatur eveniet ratione exercitationem alias quis nihil ab, neque possimus!'
  // }
  
 
]


function Testimonials() {
  return (
    <section id='testimonials'>
      <h5>Дурсамж</h5>
      <h2>Бидний дурсамж</h2>
      <Swiper className="container testimonials__container"
         // install Swiper modules
        modules={[ Pagination,]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
        >
        
         {
          data.map(({avatar,name,review},index)=>{
            return(
              <SwiperSlide  key={index} className="testimonial">
                <div className="client__avatar">
                  <img src={avatar} alt="avatar1" />
                </div>
                <h5 className='client__name'>{name}</h5>
                <small className='client__review'>
                  {review}
                </small>
                <Link to={"https://public-page-dd0.notion.site/989604fd716d408594db70e7dd944e4d?pvs=4"} >үргэлжлэл...</Link>
              </SwiperSlide>  
            )
          })
         }

      </Swiper> 
    </section>
  )
}

export default Testimonials