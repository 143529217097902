import React from 'react';
import '../App.css';
import Header from '../components/header/Header';
// import SplineComp from './components/spline/Spline.jsx';
import Nav from '../components/nav/Nav';
import Child from '../components/child/Child.jsx';
import Career from '../components/career/Career.jsx';
// import Achievements from '../components/achievements/Achievements.jsx';
import Challenge from '../components/challenge/Challenge.jsx';
import Gratitude from '../components/gratitude/Gratitude.jsx';
import Testimonials from '../components/testimonials/Testimonials';
import Contact from '../components/contact/Contact.jsx';
import Footer from '../components/footer/Footer';





export default function Top() {
  return (
    <div className="top">
      {/* <SplineComp /> */}
      <Header />
      <Nav />
      <Child />
      <Career />
      {/* <Achievements /> */}
      <Challenge />     
      <Gratitude />     
      <Testimonials />  
      <Contact />        
      <Footer />     
    </div>
  );

}

     

  




