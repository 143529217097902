import React from 'react';

// import  logo from'./logo.png';
import { Routes, Route, Link } from 'react-router-dom'
import './App.css';
import Top from './pages/Top';
import Childhood from './pages/Childhood.jsx';
import Family from './pages/Family.jsx';
import School from './pages/School.jsx';
import Nomatch from './pages/Nomatch.jsx';
import LandingPage from './pages/LandingPage.jsx';
import Engineer from './pages/Engineer.jsx';



export default function App() {
  return (
    <div className="App">
      <ul>
        <li> <Link to="/"></Link></li>
        <li> <Link to="top"></Link></li>
        <li> <Link to="childhood"></Link></li>
        <li><Link to="family"></Link></li>
        <li><Link to="school"></Link></li>
      </ul>
     
      
      
     <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/top" element={<Top />} />
        <Route path="/childhood" element={<Childhood />} />
        <Route path="/family" element={<Family />} />
        <Route path="/school" element={<School />} />
        <Route path="/engineer" element={<Engineer />} />
        <Route path="/*" element={<Nomatch />} />
     </Routes>
    </div>
  );

}

     

  




