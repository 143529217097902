import React from 'react'
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaDribbble } from "react-icons/fa";
const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="http://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
        <a href="http://github.com" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
        <a href="http://dribble.com" target="_blank" rel="noopener noreferrer"><FaDribbble /></a>
    </div>
  )
}

export default HeaderSocials