import React from 'react'
import { MdOutlineEmail } from "react-icons/md";
import './contact.css';
import { useRef } from 'react';
import emailjs from 'emailjs-com';

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_msdr27s', 'template_objvpop', form.current, {
        publicKey: 'PszsynmkjwyLMwLND',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
      e.target.reset();
  };




  return (
    <div>
        <section id='contact'>
            <h5>Шүдэнзний үйлдвэрийн тухай</h5>
            <h2>Холбоо барих</h2>
           <div className="container contact__container">
              <div className="contact__options">
                <article className="contact__option">
                  <MdOutlineEmail className='contact__option-icon'/>
                  <h4>Email</h4>
                  <h5>tsoruwork+00001@gmail.com</h5>
                  <a className='btn btn-primary' href="mailto:tsoruwork+00001@gmail.com" target='_blank' rel='noreferrer'>Илгээх</a>
                </article>
              </div>
              {/* FORM */}
              <form ref={form} onSubmit={sendEmail}>
                <input type="text" name='name' placeholder='Таны овог нэр' required />
                <input type="email" name="email" placeholder='Таны имэйл хаяг' required />
                <textarea name="message"  rows="10" placeholder='Таны мессэж' required></textarea>
                <button className='btn btn-primary' type='submit'>Илгээх</button>
              </form>

           </div>

        </section>
    </div>
  )
}

export default Contact