import React from 'react'
import './header.css'
import CTA from './CTA'
import Me from '../../assets/Screenshot 2024-02-25 at 18.41.00.png'
import HeaderSocials from './HeaderSocials'
import favicon from '../../assets/favicon.png'
import qrcode  from '../../assets/qrcode.png'
const Header = () => {
  return (
    <section id='#memories'>
      <header>
          <div className="container header__container">
             <div className="header__top-wrapper">
                <div className="header__logo">
                  <img src={favicon} alt="logo_image" />
                </div>
                <div className="header__qrcode">
                  <img src={qrcode} alt="QR_code" />
                </div>
             </div>
              <h5 className='text-light'>Цахим дурсамж</h5>
              <HeaderSocials />
              <CTA />
              <div className="wrapper__engineer">
                <div className="me">
                    <img src={Me} alt="me"/>
                </div>
                <div className="engineer__article">
                  <h2>Инженерын амьдралыг <span>"Хүсэл тэмүүлэл, Технологи</span> хоёрыг огтолцуулдаг аялал" - тай      зүйрлэж болох юм.</h2>
                  <div className="engineer__article-body">
                    <p>&diams; Инженерчлэл бол технологи, бүтээлч сэтгэлгээг нэгтгэдэг гайхалтай салбар юм.</p>
                    
                    <p>&diams; Та бага наснаасаа эхлэн электрон төхөөрөмжүүдийн доторх болон машин хэрхэн ажилладаг талаар сонирхож байсан байх. Эдгээр сонирхол нь шинжлэх ухаан, технологийн талаархи сониуч зангаас үүдэлтэй.</p>
                    
                    <p>&diams; Их дээд сургууль, мэргэжлийн сургуульд суралцах нь инженерчлэлийн үндэс суурийг тавих чухал алхам юм. Гэхдээ энэ нь хангалтгүй ,бие даан суралцах, дадлага хийх нь жинхэнэ инженер болох замыг нээж өгдөг.</p>
                   
                    <p>&diams; Төслийн бүтэлгүйтэл, сорилтууд нь өсөлтийн боломж юм. Сорилтыг даван туулж, алдаанаасаа суралцаж, дараагийн алхам руу шилжих нь чухал юм.</p>
                   
                    <p>&diams; Инженерүүд хэзээ ч тусдаа ганцаараа ажилладаггүй. Багийн гишүүдтэй хамтран ажиллаж, өөр өөр төрлийн мэргэшсэн хүмүүстэй гар нийлэн ажиллах, хүмүүсээс суралцах нь төслийн амжилтанд зайлшгүй шаардлагатай.</p>
                    
                    <p>&diams; Технологи байнга хөгжиж байдаг. Шинэ технологид суралцах, хэрэглэх нь инженерийн карьерын чухал ур чадвар юм. Байгаль орчны өөрчлөлтөд уян хатан хариу үйлдэл үзүүлэх шаардлагатай.</p>
                   
                    <p>&diams; Манлайлал ба зөвлөх нь инженерийн карьер дахь өсөлтийн чухал бүрэлдэхүүн хэсэг юм. Багийн манлайллыг харуулж, дараагийн үеийн инженерүүдэд зааварчилгаа өгснөөр та өөрийнхөө болон бусдын өсөлт хөгжилтийг дэмжих болно.</p>
                   <p>&diams; Амжилттай төслүүд, зорилгодоо хүрэх нь  инженер хүний ​​амьдралын баяр баясгалангийн эх үүсвэр юм. Хичээл зүтгэл чинь үр дүнгээ өгч, үр дүнд хүрвэл та амьдралдаа сэтгэл ханамжийг мэдрэх болно.
</p>
                   
                    <p>&diams; Инженерийн амьдрал бол технологи, хүсэл тэмүүлэл огтлолцдог гайхалтай аялал юм. Бэрхшээл, бүтэлгүйтэл, амжилт, өсөлтийг даван туулахын зэрэгцээ үргэлж урагшилж, шинэ өндөрлөгт тэмүүлэх нь чухал юм. Технологийн хүчээр дэлхийг өөрчилж, эрхэм зорилгоо биелүүлж чадна гэдгээ санаж байгаарай</p>
                  </div>

              </div>
                <a href="#contact" className='scroll__down'>Холбоо барих</a>
              </div>
          </div>
      </header>

    </section>
  )
}

export default Header