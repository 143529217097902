import React from 'react'
import './footer.css'
// import match from '../../assets/match.png'
const Footer = () => {
  return (
    <section id='footer'>
    
      <div className="footer__container">
        
          <a href="#top" className='footer__logo'>Монгол Инженер</a>
          <ul className='permalinks'>
            <li><a href="#memories">Дурсамж</a></li>
            <li><a href="#child">Бага нас</a></li>
            <li><a href="#career">Ажлын Карьер</a></li>
            {/* <li><a href="#achievements">Ололт Амжилт</a></li> */}
            {/* <li><a href="#challenge">Бэрхшээл Сорилтууд</a></li> */}
            <li><a href="#testimonials">Дурсамж</a></li>
            {/* <li><a href="#gratitude">Үлдээсэн өв</a></li> */}
          </ul>
          <div className="footer__socials">
              <a href="https://framevr.io/purvsuren">Шүдэнзний үйлдвэр</a>
              <a href="https://www.facebook.com">Facebook</a>
              
          </div>
          <div className="footer__copyright">
            <small>
              &copy;Suyo All rights reserved.
            </small>
          </div>
      </div>
    </section>
  )
}

export default Footer