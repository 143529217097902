import React from 'react'
import BlogHeader from '../components/blog_components/BlogHeader'
import BlogBody from '../components/blog_components/BlogBody'


const text = { 
    title:"Хүүхэд нас", 
    subtitle:"subtitle",
}
const content = {
  author: "нийтэлсэн: П.Цолмон",
  postTitle: "Бага нас",
  thumbnail: "Thumbnail",
  text: "text desu"

}


function Childhood() {
  return (
    <>
        <BlogHeader text={text}/>
        <BlogBody content={content}/>
    </>
  )
}

export default Childhood