import React from 'react'
import CV from '../../assets/engineer.pdf'


function CTA() {
  return (
    <div className='cta'>
        <a href="/engineer" className='btn'>Инженер</a>
        <a href={CV} download className='btn'>Татаж авах <small>(сонин)</small></a>
    </div>
  )
}

export default CTA