import React from 'react'
import BlogHeader from '../components/blog_components/BlogHeader'
import BlogBody from '../components/blog_components/BlogBody'
// import BlogFooter from '../components/blog_components/BlogFooter'

const text = {
  title:"Сургууль",
  subtitle:"Нийслэлийн 14-ээр сургууль"
};
const content = {
    author: "Ө.Цэцэг",
    postTitle:"Сургууль",
    thumbnail:"Thumbnail",
    text:"text"

}
function School() {
  return (
    <>
        <BlogHeader text={text}/>
        <BlogBody content={content}/>
        {/* <BlogFooter /> */}
    </>
  )
}

export default School