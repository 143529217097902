import React from 'react'
import BlogHeader from '../components/blog_components/BlogHeader'
import BlogBody from '../components/blog_components/BlogBody'
// import BlogFooter from '../components/blog_components/BlogFooter'
const text = {
    title:"Гэр бүл",
    subtitle:"Төрсөн гэр"
};
const content = {
    author: "Ш.Дулмаа",
    postTitle: "Төрсөн гэр бүл",
    thumbnail: "Thumbnail",
    text: "Гэр бүлийн тухай",
}


function Family() {
  return (
    <div>
        <BlogHeader text={text}/>
        <BlogBody content={content}/>
        
    </div>
  )
}

export default Family