import React from 'react'
import './gratitude.css'

const Gratitude = () => {
  return (
    <section id='gratitude'>
      <img src="https://emgeneltemplate.my.canva.site/purevsuren" alt="" srcset="" />
    </section>
  )
}

export default Gratitude;