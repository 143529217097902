import React from 'react'
// import VideoPlayer from './VideoPlayer'
function Challenge() {
  return (
    <div className="container challenge__container">
      {/* <VideoPlayer /> */}
    </div>
    
  )
}

export default Challenge