import React from 'react'


const Style = {
  marginTop: 4 + "rem",
  display: "flex",
  alignItems: "center",
  justifyContent:"center"
}

function Nomatch() {
  return (
    <div className="container">
      <h2 style={Style}>Уучлаарай Хуудас олдсонгүй</h2>
    </div>
  )
}

export default Nomatch
