import React from 'react'
import './modal.css'


function Modal(props) {
  return (
    <div>
        <div class="modal-1__wrap">
        <input type="radio" id="modal-1__open" class="modal-1__open-input" name="modal-1__trigger"/>
        <label for="modal-1__open"  class="modal-1__open-label"> Сургалтууд</label>
        <input type="radio" id="modal-1__close" name="modal-1__trigger"/>
        <div class="modal-1">
            <div class="modal-1__content-wrap">
            <label for="modal-1__close" class="modal-1__close-label">×</label>
            <div class="modal-1__content">{props.text}</div>
            </div>
            <label for="modal-1__close">
            <div class="modal-1__background"></div>
            </label>
        </div>
        </div>
    </div>
  )
}

export default Modal