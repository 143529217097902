import React from 'react'
import { Link } from 'react-router-dom'
import Thumbnail from '../../assets/match001.png'
import './blog_component.css'
import togoo from '../../assets/togoo.png'
import match1 from '../../assets/match01.png'
import match2 from '../../assets/match02.png'
import matchfactory from '../../assets/matchfactory.png'
function PostDetail() {
  return (
    <section className='post-detail'>
        <div className="container post-detail__container">
            <div className="post-detail__header">
                <div>
                    <p>Нийтэлсэн: Д.Пэлдэн</p>
                </div>
                <div className="post-detail__button">
                    <Link to={'/top'} className='btn btn-primary'>Буцах</Link>
                </div>
            </div>
            <h1>Сэлэнгэ аймаг, Сүхбаатар хот  Шүдэнзний үйлдвэр</h1>
            <div className="post-detail__thumbnail">
                <img src={Thumbnail} alt="" />
            </div>
            <p> Шүдэнз зурлаа. Шархийтэл асав. Манай шүдэнз сайн болжээ  гэж хажуугийн хүн хэллээ. Итгэж болно. Нарны ширхэг шиг гал гарт минь байна. Эцэг минь Бийск рүү жин тээж явахдаа хэт цахиур харшуулан ядаж суусныг мартдаггүй юм.


Надад ажилчны хувцас өмссөн залуу инженерийн царай зүс нүдний гал , догдлол , эрс шулуун үг яриа , зоримог шинж харагдах шиг .


Цахилгаан хатаагуурын       , найман дамжлагын зураг төсөл тооцоо бүхий хавтас гаргаж түүнийгээ “ Энэ зуны ажлын ажлын дүн “ гэж Сүхбаатар хотын шүдэнзний үйлдвэрийн залуу
инженер Пүрэвсүрэн хэлсэн юм.</p>

<img className='match__image' src={togoo} alt="" />
            <p>Долоон сая төгрөгийн үнэтэй гурван тогоо бүхий уурын зуух аварга биетэн шиг амьсгалах.
Гал асахгүй бол үйлдвэр зогсоно гэсэн үг.
Галч нар яах ч аргагүй. Зуух, нүүрсээ голж байгаа ажээ. Цаг хугацаа явж байх , бид сул зогсож байх таарч дээ!
Сэтгэл амар байх арга алга гэж ажилчид ундууцна. Үүрэг хариуцлага залуу инженерийн толгой дээр буужээ. Хүмүүсийн царай нүдэнд тусна. Бид яах вэ гэсэн асуулт тавих шиг санагдана.</p>
            <p>— Сэтгэлээ хөвчил, дүү минь, хийсэн ажлынхаа үр шимийг  хүлээж найдаж чаддаг хүн санасандаа хүрдэг юм гэж ахмад ажилчин Түмэн-Өлзий өвгөн залуу инженерт хэлжээ.

Шүдэнзний үйлдвэрийн ерөнхий инженер Пүрэвсүрэн далан гурван онд Ленинградын ойн техникийн академийн инженер технологийн анги төгсгөжээ. Гагнуурчин , токарьчин , механик 
байхдаа үйлдвэрийн бүх дамжлагыг мэддэг болов. Үйлдвэр төлөвлөгөө тасалж , ажилчид гомдол гаргах үе хааяа тохиолдоно. Үйлдвэрийн нүсэр том механизмуудыг харахад энэ чөрийсөн турьхай залуу хичнээн зориг гаргаад ч давах аргагүй санагдмаар.
Гэвч хүний тархи , зүрх хоёрын нөөц шавхагдашгүй юм. Энэ олон хүмүүст тус болох үүрэг өөрт нь байгаа. Үүний төлөө хал зовлон амсах ёстой. Хийж бүтээхэд өндөр даваа байдгыг мэдэрнэ.</p>
            <p>Далан есөн оны хүйтэн өвөл тэр эмнэлэгт хэвтэхдээ цахилгааны тухай ном уншив. Нэг өглөө үйлдвэр рүүгээ явжээ. “ Миний ядаргааны эм бол үйлдвэр, хөдөлмөр намайг аварна “ гэж тэр бодов. Ер нь өөрийгөө ялна гэдэг том тэмцэл юм.

Бүх халаалт , механик цех , токарын машин бүгд хөлджээ. Гурван зуун наян вольтын хоёр халаагуур хийж том цехэд тавихад хоёр  хоногт мөс гэсэж эхлэв. Уурын халаалтын системийг цахилгаан болговол ажил жигдэрнэ. Сул зогсох бүх шалтаг тэр л “зууханд” байлаа. Нэг өдөр ня-бо Балдан гуайтай уурын халаалтыг цахилгаан болгох тооцоо хийжээ.
“Хөөе хөө бузгай ашигтай байна. Чи түргэн хийгээд орхи ” гэж ня-бо шамдуулжээ.
Хорин хоёр жил энд тооцоо бодохдоо ийм ашигтай ажлын тооцоо тун бага бодсон юм байж.
Наян ондоо далан зургаан мянган төгрөгний цэвэр ашигтай гэсэн үг.</p>
            <p>Инженер , гагнуурчин механикч, цахилгаачин дөрвөн хүн цахилгаан хатаагуур хийж туршлаа. Шүдэнзний ишний хатаагуурт тоосонцор их учраас цахилгаан хатаагуур дэлбэрнэ гэдэг үг аянга шиг дуулдав. Хариуцлагатны зөвлөлгөөн боллоо. Уурын системийг цахилгаан болгох инженерийн төсөл, боломжуудыг авч үзлээ.

— Анхны цахилгаан хатаагч маань сайн болж. Их ашигтай байна. Уурын зогсолтыг халах цаг ирсэн бололтой. Ингэж хийснээр жилдээ сая төгрөгний орлоготой ажиллана. Үйлдвэрийн технологийг дураараа өөрчилж байна гэх үү ! Бид хэнийг хүлээж суух болж байна. Бид хийхгүй юм бол хэн хийх юм бэ? Өнөөдөр хийхгүй юм бол хэзээ хийх юм бэ.
Шийдвэрлэх алхам болно гэж нэг мастер хэллээ.

— Тэр ( уур ) саатаж бид зогсож байгаа нь үнэн . Дэвшилтэт техник , дэвшилтэд арга барил дутсан байж болно. Ишний хатаагуурт тоосонцор хуримталж Олныг хамарсан осол болж юу магад ! Цахилгаан болгочихвол энд ажиллахгүй гэсэн хүн ч байна Дэлбэрчихнэ гэж айгаад байх юм . Айх нь  ч зөв байх. Би хувьдаа энэ санаачлагийг дэмжиж чадахгүй . Дээд газрын шийдвэрийг харзнана байгаа гэж зарим нь татгалзана.</p>
            <p>— Миний хувьд мэдэх юм алга Хал балгүй зогсохдоо зогсож , хөдлөхдөө хөдлөөд уурын халаалтаараа байна байгаа. Юм янзаараа л дээр. Хийгээд алддаг явдал бий. 
Хийхгүй бол хожихгүй юмаа гэхэд хохирохгүй шүү дээ! гэж зарим нь хэллээ. 
Ингээд хариуцлагатны зөвлөлгөөн  тарав. 

Залуу инженер зорьсон ажилдаа шийдмэг оров. Зориг хүслийн гал сэтгэлд асаж, бэрхшээлтэй байх тусмаа хийх нь бахтай байв.

— Зогсонги байдлыг өөрчлөх ганц найдвар цахилгаанд байна! Маш хэрэгтэй зүйл.Танай нөхцөлд хүн хүч , нүүрс,цахилгаан аль аль нь хэмнэгдэнэ. Бүтээмж , цаг хожно гэж Сүхбаатар хотын гурил , тэжээлийн комбинатын мэргэжилтэн Корилович Пүрэвсүрэнд сургамжилжээ.

Залуу инженер хот оров. Ой модны аж үйлдвэрийн яаманд ажлаа танилцууллаа. Яам ч дэмжлээ. 

Тэтгэвэрт суусан өвгөн ажилчин Түмэн-Өлзий гуай хүртэл залуу инженертэй уулзаад</p>
<p>— Уур зогсчихоор бид цөхрөөд үүнийг нэг цахилгаан болгочих юмсан гэж хүсэж мөрөөддөг байлаа. Мөрөөдөл биелж байна, хүү минь. Олон шөнө зүүдэлж зөгнөсөн юм. Ажил хөдөлмөрөө ингэж зөгнөж мөрөөдөх ч сайхан байж. 
Төлөвлөгөө биелүүлээсэй! Гал шигээ халуун байгаарай ! гэж ерөөл тавьж байдаг юм гэв. 

Пүрэвсүрэн өгүүлэх нь: 

Үйлдвэрийн дамжлагыг цахилгаан системээр өөрчилж орхилоо. Хийдгүй л ажил байсан . Харин хүнийг яаж өөрчлөх вэ? Тун хэцүү зүйл. Гэхдээ хүний мэдлэг ямагт шинэчлэгдэж  байна.Уурын халаалтын системийг цахилгаан болгосноор бид цахилгаан урсгалын хүрээ , хязгаар, хүчний харьцаа , өөрчлөлтийг тусгах болно. Дэлбэрнэ гэж айх нь буруу бишээ , Гагцхүү хүн чинь мэдэхгүйгээсээ болж айдаг юм байна. Хатаагч гэнэт зогсож автомат салах үед ажилчин хүн цахилгааны ид шидийг бишрэнэ.</p>
<div className="match__images">
    <img className='match__image' src={match1} alt="" />
    <img className='match__image'src={match2} alt="" />
</div>
<p>1980-8-17.  Тэр өдөр , шүдэнзний үйлдвэрийн цахилгаан систем ажиллаж эхлэв.Бүх дамжлага нүргэлэн ажиллав. Хөдөлмөрийн сайхан ялалтын өдөр боллоо. Хүний сэтгэлд гэрэл гэгээ тусчээ. Шүдэнзний иш зөгийн үүр шиг харагдана. Хамгийн гол нь уурыг цахилгаан болгосонд биш , зогсонги байдлыг урагштай ажлаар сольсонд байгаа юм. 

Хүний үйл ажиллагаа цахилгаанаар амилжээ. Залуу инженерийн сэтгэлд гал шар шувууд нисэх шиг төсөөлөгдөнө. Энэ нь ухааны цуцашгүй жигүүрт шувууд юм. 

Нягтлан бодогчийн тооцооны машинд  сая гэсэн тоо гарчээ. Цахилгаан системийн ашиг орлогыг зааж байгаа нь энэ. Тэр тоо , ажилчдын сэтгэлд урам оруулсан байна. Энэ өдөр шүдэнзний үйлдвэрт дурсгалтай боллоо. Хөдөлмөрийн түүхийн шинэ хуудсыг цахилгаанаар бичив. Энэ бол бас галын цом юм. Бидний атганд  байгаа шүдэнзний шархийн асах дөлөнд цахилгааны илч бий.</p>
<figure>
    <img className='match__image' src={matchfactory} alt="matchfactory" align="left"/>
    <p>Цахилгаан хөгжим уянгалахад залуу инженерийн сэтгэл сэргэв. 
    Найман дамжлагын өөрчлөлт бол оюун ухааны яруу сайхан юм. Мөн нэг цахилгаан хөгжим нүргэлэх шиг дуулдана. Асар хүчирхэг эрин цагийн хөгжим мөн . Хөгжим цахилгаанаар амилдаг шиг, оюун санаа , сэтгэл зүрх ч бас тэгж соёолон цэцэглэдэг биш үү. Аваар ослын дэлбэрэлт болсонгүй Харин зогсонги  байдлын дэлбэрэлт болж , зогсонги муу зуршлыг эвдсэн хүмүүс яралзтал алхан ирж шинэ ажилдаа оржээ. 

    Үйлдвэрийн аварга Мягмарсүрэн гэж хорин хоёр жил ажилласан эмэгтэй зуруулын дамжлагад дандаа түрүүлдэг байжээ. Зогсонги  байдлаас ямагт зугтааж чадсан тэр эмэгтэй “ Цахилгаан биднийг хоёр дахин урагш ахиулж байна. Гурав дахин ахиулах нь мэдээж” гэлээ.</p>

</figure>
<p>Шүдэнзний хайрцагууд цувуурт хөгжмийн цохиур шиг үсчинэ. Бугантын гоолиг  улиангаруудын нарийн  ширхэг цасан цагаан цэцэг шиг дэлгэрэн ургаж , хүний халуун атганд орно. Савлагаанд  чөлөө  завгүй . Байшинд шүдэнз авдар авдараар хураалттай машинуудад ачина. Тужийн нарсны энэ зам  дагуу хувьсгалын гал ассан шиг манай үеийн амьдралын гал дөл түгж байна. Гал түгээгчид манай үеийн Прометей юм. Толгой дээр цистерний том торх шиг хатаагуур хүрчигнэн , тэнхлэг эргэлдэнэ. Цахилгаан агуу их зүйл юм. Гэвч ухаан бол бүр ч их . Хүний хүч биендээ биш, тархиндаа байдаг ажээ.

Сүхбаатар хотоос Тужийн нарсны хаяагаар өндөр хүчдэлийн ган цамхагууд төмөр далавчаа
гялалзуулан урагшид оджээ. Залуу инженер түүнийг бахдан харна.

Сэтгэлд нь асар их халуун урсгал байна. Одоо хуучны уурын зуухны байранд үйлдвэрийн хаягдал мод шатаах төхөөрөмж  хийвэл манай үйлдвэрт техникийн бүрэн бүтэн үйл ажиллагаа бий болох юм гэж инженер залуу бодлоо.

Хөдөө нутгийн залуу инженер итгэл найдвартаа эзэн болжээ. Шүдэнз шархийн асахад галын номын хуудас эргэх шиг санагдана. </p>
        </div>
    </section>
  )
}

export default PostDetail