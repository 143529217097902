
import React, { Suspense } from 'react'
import './spline.css'
const Spline = React.lazy(() => import('@splinetool/react-spline'));

function SplineComp() {
  return (
      <div className="container spline__container"> 
                <div className="spline">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Spline scene="https://prod.spline.design/ZKDSJ3sq1dMZmz88/scene.splinecode" />
                  </Suspense>
                </div>
                <div className="spline__text">
                  <h1>Монгол инженерын дурсамж </h1>
                  <div className="spline__buttons">
                    <p><a className='btn' href="/top"  >Дэлгэрэнгүй...</a></p>
                    <p><a className='btn' href="https://framevr.io/purvsuren"  >VR Өрөө</a></p>
                  </div>
                </div>
      </div> 
   )
 }
 export default SplineComp
   
   
                
                
                 
