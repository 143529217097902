import React from 'react'
import './blog_component.css'
import PostDetail from './PostDetail'

function BlogBody(props) {
  return (
   <>
         <div className="container container__blogBody">
          <PostDetail content = {props.content}/>
        </div>
   </>
  )
}

export default BlogBody