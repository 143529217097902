import React from 'react'
import BlogHeader from '../components/blog_components/BlogHeader'
import BlogBody from '../components/blog_components/BlogBody'
// import Text from '../components/blog_components/Text'
// import BlogFooter from '../components/blog_components/BlogFooter'

const text = {
    title: "Инженер",
    subtitle:"(найруулал)",
    writer:'Д.Пэлдэн'
}
const content = {
  author:"Нийтлэлч: Д.Пэлдэн",
  thumbnail:"Thumbnail",
  postTitle: "Инженер",
  
}

function Engineer() {
  return (
    <>
      <BlogHeader text={text}/>
      <BlogBody content={content}/>
    </> 
  )
}

export default Engineer