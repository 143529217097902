import React from 'react'
import './child.css'
import child from'../../assets/childhood2.png'
import { FaChildReaching } from "react-icons/fa6";
import { FaChild } from "react-icons/fa";
import { MdFamilyRestroom } from "react-icons/md";
import { IoSchoolOutline } from "react-icons/io5";



const Child = () => {
  return (
    <section id='child'>
      <h5>1949~1973</h5>
      <h2><FaChild /> Ⅱ. Бага нас сурагч, оюутан үе</h2>
      <div className="container child__container">
        <div className="child__me">
          <div className="child__me-image">
            <img src={child} alt="my childhood"/>
          </div>
        </div>

        <div className="child__content">
          <div className="child__cards">
           <a href="https://www.notion.so/public-page-dd0/c2421fcc9a424b86b2d626ee6bb94306?pvs=4">
            <article className='child__card'>
              <FaChildReaching className='child__icon' />
              <h5>Хүүхэд нас</h5>
              <small>Сонирхол Хобби</small>
            </article>
           </a>
           <a href="https://public-page-dd0.notion.site/88e0d9cad6c4496f96797527e09375f0?pvs=4">
            <article className='child__card'>
              <MdFamilyRestroom className='child__icon' />
              <h5>Гэр бүл</h5>
              <small>Гэрлэсэн</small>
            </article>
            </a> 
            <a href="https://public-page-dd0.notion.site/2223cee2e0e241ddba95909775f028dc?pvs=4">
              <article className='child__card'>
                <IoSchoolOutline className='child__icon' />
                <h5>Сургууль</h5>
                <small>Бага, Дунд, Дээд</small>
              </article>
            </a>
          </div>

          <p>
          Хүний хүмүүжил, амьдарч буй эрин үе нь түүний үнэт зүйл, итгэл үнэмшил, боломжуудыг төлөвшүүлж, эцсийн дүндээ шийдвэр, үзэл бодол, нийгэмд оруулах хувь нэмэрт нь нөлөөлж ирээдүйг нь бүрдүүлдэг.   
          </p>
          
          <a href="https://emgeneltemplate.my.canva.site/purevsuren" className='btn btn-primary'>Дурсгал</a>
        </div>
      </div>

    </section>
  )
}

export default Child