import React from 'react'
import './career.css'
import { FaCheckSquare } from "react-icons/fa";
import { GrUserWorker } from "react-icons/gr";
import Modal from './Modal';

const text = "1976 онд ЗХУ - н Усань - Сибирскийн шүдэнзний үйлдвэрт 14 хоног дадлага хийсэн.1978 онд Монгол улсын их сургуулийн дэргэдэх улс ардын аж ахуйн салбаруудын удирдах боловсон хүчний эдийн засаг техникийн мэдлэг дээшлүүлэх байнгын 45 хоногийн курс. 1982 онд ЗХУ - н Ленинград хотын ойн техникийн академид 45 хоног мэдлэг дээшлүүлэх курст суралцсан."



const Career = () => {
  return (
    <section id='career'>
      <h5>1973~2001он</h5>
      <h2><GrUserWorker /> Ⅲ.Ажлын Карьер</h2>
      <div className="container container__career">
        <div className="factory__career">
          <h3>Шүдэнзний үйлдвэр</h3>
          <career className="career__content">
            <article className='career__details'>
              <FaCheckSquare   className='career__details-icon' />
              <div>
                <h4>Гагнуурчин</h4>
                <small className='text-light'>Цахилгаан болон хийн гагнуур</small>
              </div>
            </article>

            <article className='career__details'>
              <FaCheckSquare   className='career__details-icon' />
              <div>
                <h4>Токарьчин</h4>
                <small className='text-light'>Туршлагажсан</small>
              </div>

            </article>
            <article className='career__details'>
              <FaCheckSquare   className='career__details-icon' />
              <div>
                <h4>Механик</h4>
                <small className='text-light'>Туршлагажсан</small>
              </div>

            </article>
            <article className='career__details'>
              <FaCheckSquare   className='career__details-icon' />
              <div>
                <h4>Инженер</h4>
                <small className='text-light'>Автоматик</small>
              </div>
            </article>
            <h5>
               <Modal text={text}/>
            </h5>
          </career>
        </div>
        <div className="nonfactory__career">
          <h3>Улс төрийн ажил, Хувийн Бизнес </h3>
          <career className="career__content">
            <article className='career__details'>
              <FaCheckSquare   className='career__details-icon' />
              <div>
                <h4>Аймгийн АҮХАА Барилга хэлтсийн эрхлэгч</h4>
                <small className='text-light'></small>
              </div>
            </article>
            <article className='career__details'>
              <FaCheckSquare   className='career__details-icon' />
              <div>
                <h4>"Шитех"компаны үүсгэн байгуулагч, компанын захирал</h4>
                <small className='text-light'></small>
              </div>
            </article>
          </career>
        </div>

      </div>
      

    </section>
  )
}

export default Career